import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import './App.css';
const reCaptcha = process.env.REACT_APP_RECAPTCHA_KEY;

console.log({reCaptcha})

function App() {
  const [token, setToken] = useState('')
  return (
    <div className="App">
      <ReCAPTCHA
          sitekey={reCaptcha}
          onChange={(value) => setToken(value)}
        />
       <p> {token}</p>
    </div>
  );
}

export default App;
